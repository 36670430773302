"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.multiVotePayload = void 0;
/**
 * Payload to match `VoteMessageTypes` (EIP712)
 */
const multiVotePayload = (votes) => {
    return {
        votes: votes.map(v => {
            return { ...v.toPayload() };
        }),
    };
};
exports.multiVotePayload = multiVotePayload;
