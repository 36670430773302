"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signPayload = exports.StoredReply = exports.Reply = exports.Community = exports.Vote = exports.SignatureState = exports.Direction = exports.DeleteProposal = exports.InfiniteAuctionProposal = exports.UpdatedProposal = exports.Proposal = exports.StoredInfiniteAuction = exports.InfiniteAuction = exports.StoredTimedAuction = exports.TimedAuction = exports.Signable = void 0;
const eip712Types_1 = require("./types/eip712Types");
class Signable {
    async typedSignature(signer, domainSeparator, eip712MessageType) {
        const typedSigner = signer;
        // parse reqAmount to support decimal values when signing an uint256 type
        let payload = this.toPayload();
        if (payload.reqAmount)
            payload.reqAmount = payload.reqAmount.toString();
        return await typedSigner._signTypedData(domainSeparator, eip712MessageType, payload);
    }
    async signedPayload(signer, isContract, eip712MessageTypes) {
        const jsonPayload = this.jsonPayload();
        const address = await signer.getAddress();
        let signature;
        if (isContract)
            signature = await signer.signMessage(jsonPayload);
        if (eip712MessageTypes)
            signature = await this.typedSignature(signer, eip712Types_1.DomainSeparator, eip712MessageTypes);
        if (!signature)
            throw new Error(`Error signing payload.`);
        return {
            signedData: {
                message: Buffer.from(jsonPayload).toString('base64'),
                signature: signature,
                signer: address,
            },
            address,
            messageTypes: eip712MessageTypes,
            domainSeparator: eip712Types_1.DomainSeparator,
            ...this.toPayload(),
        };
    }
    /**
     * Signed payload with supplied signature
     */
    async presignedPayload(signer, signature, jsonPayload, eip712MessageTypes) {
        const address = await signer.getAddress();
        return {
            signedData: {
                message: Buffer.from(jsonPayload ? jsonPayload : this.jsonPayload()).toString('base64'),
                signature,
                signer: address,
            },
            address,
            messageTypes: eip712MessageTypes,
            domainSeparator: eip712Types_1.DomainSeparator,
            ...this.toPayload(),
        };
    }
    jsonPayload() {
        return JSON.stringify(this.toPayload());
    }
}
exports.Signable = Signable;
class TimedAuction extends Signable {
    constructor(visible, title, startTime, proposalEndTime, votingEndTime, fundingAmount, currencyType, numWinners, community, communityId, balanceBlockTag, description, propStrategy, voteStrategy, displayComments, propStrategyDescription, voteStrategyDescription) {
        super();
        this.visible = visible;
        this.title = title;
        this.startTime = startTime;
        this.proposalEndTime = proposalEndTime;
        this.votingEndTime = votingEndTime;
        this.fundingAmount = fundingAmount;
        this.currencyType = currencyType;
        this.numWinners = numWinners;
        this.community = community;
        this.communityId = communityId;
        this.balanceBlockTag = balanceBlockTag;
        this.description = description;
        this.propStrategy = propStrategy;
        this.voteStrategy = voteStrategy;
        this.displayComments = displayComments;
        this.propStrategyDescription = propStrategyDescription;
        this.voteStrategyDescription = voteStrategyDescription;
    }
    toPayload() {
        return {
            visible: this.visible,
            title: this.title,
            startTime: this.startTime.toISOString(),
            proposalEndTime: this.proposalEndTime.toISOString(),
            votingEndTime: this.votingEndTime.toISOString(),
            fundingAmount: this.fundingAmount,
            currencyType: this.currencyType,
            numWinners: this.numWinners,
            community: this.community,
            communityId: this.communityId,
            balanceBlockTag: this.balanceBlockTag,
            description: this.description,
            propStrategy: this.propStrategy,
            voteStrategy: this.voteStrategy,
            displayComments: this.displayComments,
            propStrategyDescription: this.propStrategyDescription,
            voteStrategyDescription: this.voteStrategyDescription,
        };
    }
}
exports.TimedAuction = TimedAuction;
class StoredTimedAuction extends TimedAuction {
    static FromResponse(response) {
        const parsed = {
            ...response,
            startTime: new Date(response.startTime),
            proposalEndTime: new Date(response.proposalEndTime),
            votingEndTime: new Date(response.votingEndTime),
        };
        return parsed;
    }
}
exports.StoredTimedAuction = StoredTimedAuction;
class InfiniteAuction extends Signable {
    constructor(visible, title, startTime, fundingAmount, currencyType, communityId, balanceBlockTag, description, quorumFor, quorumAgainst, votingPeriod, propStrategy, voteStrategy, displayComments, propStrategyDescription, voteStrategyDescription) {
        super();
        this.visible = visible;
        this.title = title;
        this.startTime = startTime;
        this.fundingAmount = fundingAmount;
        this.currencyType = currencyType;
        this.communityId = communityId;
        this.balanceBlockTag = balanceBlockTag;
        this.description = description;
        this.quorumFor = quorumFor;
        this.quorumAgainst = quorumAgainst;
        this.votingPeriod = votingPeriod;
        this.propStrategy = propStrategy;
        this.voteStrategy = voteStrategy;
        this.displayComments = displayComments;
        this.propStrategyDescription = propStrategyDescription;
        this.voteStrategyDescription = voteStrategyDescription;
    }
    toPayload() {
        return {
            visible: this.visible,
            title: this.title,
            startTime: this.startTime.toISOString(),
            fundingAmount: this.fundingAmount,
            currencyType: this.currencyType,
            communityId: this.communityId,
            balanceBlockTag: this.balanceBlockTag,
            description: this.description,
            quorumFor: this.quorumFor,
            quorumAgainst: this.quorumAgainst,
            votingPeriod: this.votingPeriod,
            propStrategy: this.propStrategy,
            voteStrategy: this.voteStrategy,
            displayComments: this.displayComments,
            propStrategyDescription: this.propStrategyDescription,
            voteStrategyDescription: this.voteStrategyDescription,
        };
    }
}
exports.InfiniteAuction = InfiniteAuction;
class StoredInfiniteAuction extends InfiniteAuction {
    static FromResponse(response) {
        const parsed = {
            ...response,
            startTime: new Date(response.startTime),
        };
        return parsed;
    }
}
exports.StoredInfiniteAuction = StoredInfiniteAuction;
class Proposal extends Signable {
    constructor(title, what, tldr, auctionId, parentType = 'auction') {
        super();
        this.title = title;
        this.what = what;
        this.tldr = tldr;
        this.auctionId = auctionId;
        this.parentType = parentType;
    }
    toPayload() {
        return {
            title: this.title,
            what: this.what,
            tldr: this.tldr,
            parentAuctionId: this.auctionId,
            parentType: this.parentType,
        };
    }
}
exports.Proposal = Proposal;
class UpdatedProposal extends Proposal {
    constructor(id, title, what, tldr, auctionId, reqAmount, parentType = 'auction') {
        super(title, what, tldr, auctionId, parentType);
        this.id = id;
        this.title = title;
        this.what = what;
        this.tldr = tldr;
        this.auctionId = auctionId;
        this.reqAmount = reqAmount;
        this.parentType = parentType;
    }
    toPayload() {
        return {
            id: this.id,
            reqAmount: this.reqAmount,
            ...super.toPayload(),
        };
    }
}
exports.UpdatedProposal = UpdatedProposal;
class InfiniteAuctionProposal extends Signable {
    constructor(title, what, tldr, auctionId, reqAmount, parentType = 'infinite-auction') {
        super();
        this.title = title;
        this.what = what;
        this.tldr = tldr;
        this.auctionId = auctionId;
        this.reqAmount = reqAmount;
        this.parentType = parentType;
    }
    toPayload() {
        return {
            title: this.title,
            what: this.what,
            tldr: this.tldr,
            parentAuctionId: this.auctionId,
            parentType: this.parentType,
            reqAmount: this.reqAmount,
        };
    }
}
exports.InfiniteAuctionProposal = InfiniteAuctionProposal;
class DeleteProposal extends Signable {
    constructor(id) {
        super();
        this.id = id;
    }
    toPayload() {
        return {
            id: this.id,
        };
    }
}
exports.DeleteProposal = DeleteProposal;
var Direction;
(function (Direction) {
    Direction[Direction["Up"] = 1] = "Up";
    Direction[Direction["Down"] = 2] = "Down";
    Direction[Direction["Abstain"] = 0] = "Abstain";
})(Direction || (exports.Direction = Direction = {}));
var SignatureState;
(function (SignatureState) {
    SignatureState["PENDING_VALIDATION"] = "PENDING_VALIDATION";
    SignatureState["FAILED_VALIDATION"] = "FAILED_VALIDATION";
    SignatureState["VALIDATED"] = "VALIDATED";
})(SignatureState || (exports.SignatureState = SignatureState = {}));
class Vote extends Signable {
    constructor(direction, proposalId, weight, communityAddress, signatureState, blockHeight) {
        super();
        this.direction = direction;
        this.proposalId = proposalId;
        this.weight = weight;
        this.communityAddress = communityAddress;
        this.signatureState = signatureState;
        this.blockHeight = blockHeight;
    }
    toPayload() {
        return {
            direction: this.direction,
            proposalId: this.proposalId,
            weight: this.weight,
            communityAddress: this.communityAddress,
            blockHeight: this.blockHeight,
        };
    }
}
exports.Vote = Vote;
class Community extends Signable {
    constructor(id, contractAddress, name, profileImageUrl, numAuctions, numProposals, ethFunded, totalFunded, description) {
        super();
        this.id = id;
        this.contractAddress = contractAddress;
        this.name = name;
        this.profileImageUrl = profileImageUrl;
        this.numAuctions = numAuctions;
        this.numProposals = numProposals;
        this.ethFunded = ethFunded;
        this.totalFunded = totalFunded;
        this.description = description;
    }
    toPayload() {
        return {
            id: this.id,
            contractAddress: this.contractAddress,
            name: this.name,
            profileImageUrl: this.profileImageUrl,
            numAuctions: this.numAuctions,
            numProposals: this.numProposals,
            ethFunded: this.ethFunded,
            totalFunded: this.totalFunded,
            description: this.description,
        };
    }
}
exports.Community = Community;
class Reply extends Signable {
    constructor(communityAddress, blockTag, proposalId, content) {
        super();
        this.communityAddress = communityAddress;
        this.blockTag = blockTag;
        this.proposalId = proposalId;
        this.content = content;
    }
    toPayload() {
        return {
            communityAddress: this.communityAddress,
            blockTag: this.blockTag,
            proposalId: this.proposalId,
            content: this.content,
        };
    }
}
exports.Reply = Reply;
class StoredReply {
    constructor(id, createdAt, proposalId, content, address) {
        this.id = id;
        this.createdAt = createdAt;
        this.proposalId = proposalId;
        this.content = content;
        this.address = address;
        this.id = id;
        this.createdAt = new Date(this.createdAt);
        this.proposalId = proposalId;
        this.content = content;
        this.address = address;
    }
}
exports.StoredReply = StoredReply;
const signPayload = async (signer, payload) => await signer.signMessage(payload);
exports.signPayload = signPayload;
