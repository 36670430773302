"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReplyMessageTypes = exports.DeleteProposalMessageTypes = exports.EditProposalMessageTypes = exports.InfiniteAuctionProposalMessageTypes = exports.TimedAuctionProposalMessageTypes = exports.VoteMessageTypes = exports.DomainSeparator = void 0;
exports.DomainSeparator = {
    name: 'Prop House',
};
exports.VoteMessageTypes = {
    Votes: [{ name: 'votes', type: 'Vote[]' }],
    Vote: [
        { name: 'direction', type: 'uint256' },
        { name: 'proposalId', type: 'uint256' },
        { name: 'weight', type: 'uint256' },
        { name: 'communityAddress', type: 'address' },
        { name: 'blockHeight', type: 'uint256' },
    ],
};
exports.TimedAuctionProposalMessageTypes = {
    Proposal: [
        { name: 'title', type: 'string' },
        { name: 'tldr', type: 'string' },
        { name: 'what', type: 'string' },
        { name: 'parentAuctionId', type: 'uint256' },
    ],
};
exports.InfiniteAuctionProposalMessageTypes = {
    Proposal: [
        { name: 'title', type: 'string' },
        { name: 'tldr', type: 'string' },
        { name: 'what', type: 'string' },
        { name: 'parentAuctionId', type: 'uint256' },
        { name: 'reqAmount', type: 'string' },
    ],
};
exports.EditProposalMessageTypes = {
    Proposal: [
        { name: 'id', type: 'uint256' },
        { name: 'title', type: 'string' },
        { name: 'tldr', type: 'string' },
        { name: 'what', type: 'string' },
        { name: 'parentAuctionId', type: 'uint256' },
    ],
};
exports.DeleteProposalMessageTypes = {
    Proposal: [{ name: 'id', type: 'uint256' }],
};
exports.ReplyMessageTypes = {
    Reply: [
        { name: 'content', type: 'string' },
        { name: 'proposalId', type: 'uint256' },
        { name: 'communityAddress', type: 'address' },
        { name: 'blockTag', type: 'uint256' },
    ],
};
