"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.multiVoteSignature = void 0;
const eip712Types_1 = require("../types/eip712Types");
/**
 * Signature for payload of all votes
 */
const multiVoteSignature = async (signer, isContract, allVotesPayload) => {
    if (isContract)
        return await signer.signMessage(JSON.stringify(allVotesPayload));
    const typedSigner = signer;
    return await typedSigner._signTypedData(eip712Types_1.DomainSeparator, eip712Types_1.VoteMessageTypes, allVotesPayload);
};
exports.multiVoteSignature = multiVoteSignature;
